import { useCallback, useEffect } from "react";

export const useCustomCssVariables = () => {
  /**
   * Sets correct 1vw value of clientWidth to a css variable
   * @description default css `vw` calculating width using both client width and scrollbar width
   */
  const setCorrectVWInPixels = useCallback(() => {
    let vw = document.documentElement.clientWidth / 100;
    document.documentElement.style.setProperty("--vw", `${vw}px`);
  }, []);

  useEffect(() => {
    setCorrectVWInPixels();
    window.addEventListener("resize", setCorrectVWInPixels);

    return () => {
      window.removeEventListener("resize", setCorrectVWInPixels);
    };
  }, [setCorrectVWInPixels]);
};

import classNames from "classnames";
import React from "react";

type SingleLinkProps = (
  | React.AnchorHTMLAttributes<HTMLAnchorElement>
  | React.ButtonHTMLAttributes<HTMLButtonElement>
) & {
  children: string | React.ReactNode;
  component?: "link" | "button";
  href?: string;
};

const SingleLink = ({
  href,
  children,
  className,
  component = "link",
  ...rest
}: SingleLinkProps) => {
  const focusCommonStyles =
    "focus:outline-none focus:ring-none focus-visible:outline-none focus-visible:ring-2 focus-visible:ring focus-visible:ring-cerise";
  const classes = classNames(
    "align-left flex inline-flex w-full justify-between py-3 text-[18px] font-bold leading-[130%] text-black-rock",
    "transition-[color,transform] duration-200",
    "hover:text-cerise data-[state=open]:text-cerise",
    "[&_.second-line]:data-[state=open]:rotate-90 [&_.second-line]:data-[state=open]:opacity-0",
    focusCommonStyles,
    className
  );

  if (component === "button") {
    return (
      <button
        type="button"
        className={classes}
        {...(rest as React.ButtonHTMLAttributes<HTMLButtonElement>)}
      >
        {children}
      </button>
    );
  }

  return (
    <a
      href={href}
      className={classes}
      {...(rest as React.AnchorHTMLAttributes<HTMLAnchorElement>)}
    >
      {children}
    </a>
  );
};

export default SingleLink;

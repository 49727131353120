import React from "react";
import * as Accordion from "@radix-ui/react-accordion";
import classNames from "classnames";

type AccordionTriggerProps = {
  children: string | React.ReactNode;
};

const AccordionTrigger = ({ children, ...props }: AccordionTriggerProps) => {
  const focusCommonStyles =
    "focus:outline-none focus:ring-none focus-visible:outline-none focus-visible:ring-2 focus-visible:ring focus-visible:ring-cerise";

  return (
    <Accordion.Header className="flex w-full">
      <Accordion.Trigger
        className={classNames(
          "align-left text-black-rock flex inline-flex w-full justify-between py-3 font-bold text-[18px] leading-[130%]",
          "transition-[color,transform] duration-200",
          "hover:text-cerise data-[state=open]:text-cerise",
          "[&_.second-line]:data-[state=open]:rotate-90 [&_.second-line]:data-[state=open]:opacity-0",
          focusCommonStyles
        )}
        {...props}
      >
        <span>{children}</span>
        <span className={"relative inline-flex h-[24px] w-[24px] p-[2px]"}>
          <span className={"relative flex h-full w-full flex-col"}>
            <span
              className={
                "absolute left-0 top-[calc(50%_-_1px)] h-[2px] w-[20px] bg-[currentColor] transition-[color,transform] duration-200"
              }
            />
            <span
              className={
                "second-line opacity-1 absolute left-[calc(50%_-_1px)] h-[20px] w-[2px] rotate-0 bg-[currentColor] transition-[color,transform,opacity] duration-200"
              }
            />
          </span>
        </span>
      </Accordion.Trigger>
    </Accordion.Header>
  );
};

export default AccordionTrigger;

import React, { ForwardedRef } from "react";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import classNames from "classnames";

type ListItemProps = React.LinkHTMLAttributes<HTMLAnchorElement> & {
  className?: string;
  children: string | React.ReactNode;
  title: string;
  href: string;
  onTrackEvent: (trackProps: { text: string; url: string }) => Promise<void>;
};

const ListItem = React.forwardRef(
  (
    { className, children, title, onTrackEvent, ...props }: ListItemProps,
    forwardedRef: ForwardedRef<HTMLAnchorElement>
  ) => (
    <NavigationMenu.Link asChild>
      <a
        className={classNames(
          "ga-nav-link flex h-full flex-col gap-[10px] bg-transparent px-[10px] py-2 transition-colors duration-200 ease-in-out",
          "hover:bg-[#AAAAB266]",
          "active:bg-[#AAAAB2B2]",
          "focus:outline-none focus-visible:outline-none focus-visible:ring focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-cerise",
          className
        )}
        onClick={async () =>
          await onTrackEvent({ text: title, url: props.href })
        }
        {...props}
        ref={forwardedRef}
      >
        <p className="font-bold text-[14px] leading-[130%] text-mono-70">
          {title}
        </p>
        {children && (
          <p className="text-[14px] leading-[130%]  text-mono-40">{children}</p>
        )}
      </a>
    </NavigationMenu.Link>
  )
);

export default ListItem;

export const SITE_BASE_FALLBACK = "https://sifted.eu";

export const correctQuery = (url: string, arrayElement: 0 | 1 = 0) => {
  const isHashOnly = url.startsWith("#");

  if (isHashOnly) return url;
  const isRelative = !url.match(/^(?:\w+:)?\/\/[^/]+/);
  const urlObject = new URL(url, isRelative ? SITE_BASE_FALLBACK : undefined);
  const searchParams = new URLSearchParams(urlObject.search);

  searchParams.forEach((value, paramName) => {
    const values = value.split("|");

    if (arrayElement < values.length) {
      searchParams.set(paramName, values[arrayElement].replace(/[{()}]/g, ""));
    }
  });

  urlObject.search = searchParams.toString();

  return isRelative
    ? urlObject.toString().replace(SITE_BASE_FALLBACK, "")
    : urlObject.toString();
};

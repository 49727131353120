import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import dynamic from "next/dynamic";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";

import { useCustomCssVariables } from "lib/hooks/useCustomCssVariables";
import useSiftedMediaQueries from "lib/hooks/useSiftedMediaQueries";
import { setCssVariable } from "lib/utils";

import BurgerButton from "./burgerButton/burgerButton";
import NavLogo from "./navLogo/navLogo";
import PrimaryMenu from "./primaryMenu/primaryMenu";
import Sidebar from "./sidebar/sidebar";

const SCROLL_THRESHOLD = 0;

type NavStates = "scrollingUp" | "scrollingDown" | "top";

const NavUser = dynamic(() => import("./navUser/navUser"), {
  ssr: false,
});

type NavigationProps = {
  stickyContent?: React.ReactNode;
};

const Navigation = ({ stickyContent }: NavigationProps) => {
  useCustomCssVariables();
  const [navState, setNavState] = useState<NavStates>("top");
  const { desktop } = useSiftedMediaQueries();
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    let prevScrollPos = window.scrollY;
    let animationFrame: null | number = null;

    const setHeight = (isClosed: boolean) => {
      // set height as 0 only if it's not on desktop (navbar always visible on desktop)
      ref.current &&
        setCssVariable(
          "--headerHeight",
          `${
            isClosed && !desktop
              ? 0
              : ref.current.getBoundingClientRect().height
          }px`
        );
    };

    function onScroll() {
      animationFrame = window.requestAnimationFrame(function tick() {
        const currentScrollPos = window.scrollY;
        const scrollingUp = prevScrollPos > currentScrollPos;
        const reachedTop = currentScrollPos <= SCROLL_THRESHOLD;

        if (scrollingUp) {
          setNavState("scrollingUp");
          setHeight(false);
        } else {
          setNavState("scrollingDown");
          setHeight(true);
        }
        if (reachedTop) {
          setHeight(false);
          setNavState("top");
        }

        prevScrollPos = currentScrollPos;
      });
    }

    setHeight(false);

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);

      if (animationFrame !== null) {
        window.cancelAnimationFrame(animationFrame);
      }
    };
  }, [ref, desktop]);

  const handleSidebarClose = () => {
    setSidebarOpened(false);
  };

  return (
    <div
      ref={ref}
      data-testid={"navigation__root"}
      data-scrolling={navState}
      id={"navigation"}
      className={classNames(
        "js-fixed fixed left-0 top-0 z-20 w-full transform transition-transform duration-200",
        {
          "-translate-y-100 lg:translate-y-0": navState === "scrollingDown",
        }
      )}
    >
      <NavigationMenu.Root>
        <div
          className={
            "z-1 relative flex w-full border-b border-black-rock bg-[#F4F4F6]"
          }
        >
          <div
            className={
              "container m-auto h-[60px] w-full max-w-full px-[20px] lg:h-[68px] lg:max-w-[1280px] lg:px-[30px]"
            }
          >
            <div
              className={`align-center flex h-100 items-center justify-between`}
            >
              <div className="flex items-center justify-between lg:mr-[25px]">
                <div className="mr-3 lg:mr-[46px]">
                  <BurgerButton
                    opened={sidebarOpened}
                    ariaControls={"sidebar"}
                    onClick={(e) => {
                      e.preventDefault();
                      setSidebarOpened(!sidebarOpened);
                    }}
                  />
                </div>
                <div className={"w-[103px] lg:w-[139px]"}>
                  <NavLogo />
                </div>
              </div>
              <div className={"hidden w-full lg:flex"}>
                <PrimaryMenu />
              </div>
              <NavUser />
            </div>
          </div>
        </div>
        <NavigationMenu.Viewport
          className={classNames(
            "data-[state=open]:animate-scaleIn data-[state=closed]:animate-scaleOut origin-[top_left] overflow-hidden",
            "bg-[#F6F6F6] bg-opacity-[0.98] shadow-[0_0_30px_0_rgba(0,_0,_0,_0.10)]",
            "h-[var(--radix-navigation-menu-viewport-height)] w-full",
            "transition-[width,_height] duration-300"
          )}
        />
      </NavigationMenu.Root>
      <Sidebar opened={sidebarOpened} onClose={handleSidebarClose} />
      {stickyContent && <div className={"relative"}>{stickyContent}</div>}
    </div>
  );
};

export default Navigation;

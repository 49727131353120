import React, { DOMAttributes } from "react";
import classNames from "classnames";

type ButtonCommonProps = Partial<
  Omit<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    keyof DOMAttributes<any> | "onBeforeToggle" | "onToggle"
  >
>;
type LinkCommonProps = Partial<
  Omit<
    React.LinkHTMLAttributes<Omit<HTMLLinkElement, "href">>,
    | "type"
    | "as"
    | "href"
    | keyof DOMAttributes<any>
    | "onBeforeToggle"
    | "onToggle"
  >
>;

interface CommonProps<T>
  extends ButtonCommonProps,
    LinkCommonProps,
    DOMAttributes<T> {}

type IconButtonProps<T = any> = CommonProps<T> & {
  children: React.ReactNode;
  component?: "button" | "link";
  className?: string;
  disabled?: boolean;
  href?: string;
  target?: string;
  label?: string | React.ReactElement | React.ReactNode;
  variant?: "square" | "rounded";
  labelPosition?: "start" | "end";
  color?: "black" | "white-cerise";
};

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      children,
      component = "button",
      labelPosition = "end",
      href,
      className,
      label,
      variant = "square",
      color = "black",
      ...props
    },
    ref
  ) => {
    const defaultStyles =
      "relative inline-flex items-center justify-center leading-[100%] duration-200 ease-in-out transition-[background-color,box-shadow,color,border-color]";
    const defaultAfterStyles =
      "after:pointer-events-none after:bg-black-rock after:absolute after:bottom-0 after:left-0 after:right-0 after:top-0 after:opacity-0 after:duration-200 after:ease-in-out after:transition-opacity after:content-['']";
    const focusCommonStyles =
      "focus:outline-none focus-visible:outline-none focus-visible:ring-2 focus-visible:ring focus-visible:ring-inset";
    const disabledStyles =
      "disabled:pointer-events-none disabled:text-mono-20 disabled:user-select-none";

    const commonStyles = classNames(
      defaultStyles,
      defaultAfterStyles,
      disabledStyles,
      focusCommonStyles
    );

    const variantStyles = {
      square: "rounded-none bg-transparent focus-visible:ring-black",
      rounded:
        "rounded-full after:rounded-full bg-[#C8C8CC] hover:after:opacity-[10%] active:after:opacity-[15%]",
    }[variant];

    const squareColorStyles = {
      "square-black":
        "text-black-rock hover:after:opacity-[10%] active:after:opacity-[15%] focus-visible:ring-black",
      "square-white-cerise":
        "text-white hover:after:opacity-0 active:after:opacity-0 hover:bg-cerise active:bg-cerise focus-visible:ring-white",
    }[`${variant}-${color}`];

    const sizeStyles = classNames({
      "w-[36px] h-[36px] p-[6px]": !label && variant === "square",
      "h-[36px] p-[6px] max-w-fit": label && variant === "square",
      "h-[40px] w-[40px]": variant === "rounded",
    });

    const componentProps = {
      className: classNames(
        commonStyles,
        variantStyles,
        squareColorStyles,
        sizeStyles,
        className,
        {
          "pointer-events-none": props.disabled,
        }
      ),
    };

    const getChildren = () => {
      return (
        <>
          {label && labelPosition === "start" && variant !== "rounded" && (
            <div className={"mr-2 font-bold text-[16px] leading-[130%]"}>
              {label}
            </div>
          )}
          <div
            className={
              "inline-flex h-[24px] w-[24px] items-center justify-center"
            }
          >
            {children}
          </div>
          {label && labelPosition === "end" && variant !== "rounded" && (
            <div className={"ml-2 font-bold text-[16px] leading-[130%]"}>
              {label}
            </div>
          )}
        </>
      );
    };

    if (component === "button") {
      return (
        <button ref={ref} {...componentProps} {...props}>
          {getChildren()}
        </button>
      );
    }

    if (!href) {
      return null;
    }

    return (
      <a
        ref={ref as React.LegacyRef<HTMLAnchorElement> | undefined}
        href={href}
        referrerPolicy={"strict-origin-when-cross-origin"}
        tabIndex={props.disabled ? -1 : undefined}
        {...componentProps}
        {...props}
      >
        {getChildren()}
      </a>
    );
  }
);

export default IconButton;

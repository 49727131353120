import classNames from "classnames";
import { useRouter } from "next/router";

import { useSitewideOptionData } from "lib/store/sitewideOption";
import { useMixpanelTracking } from "lib/hooks/useMixpanelTracking";

import IconButton from "components/iconButton/iconButton";
import Markdown from "components/markdown/markdown";

import Logo from "./logo/logo";
import { correctQuery } from "./utils/link";

type FooterProps = {
  mobileView?: boolean;
  /**
   * Allow users to create links with different utms for sidebar footer and navbar footer
   * @Example: https://jobs.sifted.eu/?source={footer|navbar}&utm_source={footer|navbar}&utm_medium={partner_referral|partner_referral}
   * utmArrayIndex - index of an element to pick, for example 0 = footer, 1 = navbar
   */
  queryArrayIndex?: 0 | 1;
};

const Footer = ({ mobileView = false, queryArrayIndex = 0 }: FooterProps) => {
  const {
    footerCopyright,
    footerSocials,
    footerLogoLabel,
    footerExtraList,
    footerPrimaryList,
    footerSecondaryList,
    footerCommunityList,
    footerExtraListColumnLabel,
    footerCommunityListColumnLabel,
    footerPrimaryListColumnLabel,
    footerSecondaryListColumnLabel,
  } = useSitewideOptionData();
  const { track } = useMixpanelTracking();
  const router = useRouter();

  if (!footerCopyright) return null;

  const handleTracking = async ({
    text,
    url,
  }: {
    text: string;
    url: string;
  }) => {
    await track("Footer link click", {
      "Click text": text,
      "Click link": url,
      "Page path": router.asPath,
      Component: "Footer",
    });
  };

  const handleSocialTracking = async ({
    text,
    url,
  }: {
    text: string;
    url: string;
  }) => {
    await track("Footer social click", {
      "Click text": text,
      "Click link": url,
      "Page path": router.asPath,
      Component: "Footer",
    });
  };

  const getYear = () => {
    return new Date().getFullYear();
  };

  const focusCommonStyles =
    "focus:outline-none focus-visible:outline-none focus-visible:ring-2 focus-visible:ring focus-visible:ring-cerise";

  const containerCommonStyles =
    "container m-auto w-full px-5 md:px-[40px] lg:px-0 max-w-[1220px] [.isMobileView_&]:px-5";

  return (
    <footer
      className={classNames(
        "group w-full bg-[#C8C8CC] py-[50px] text-black-rock",
        {
          isMobileView: mobileView,
          "js-footer-gap": !mobileView,
        }
      )}
    >
      <div className={"relative"}>
        <div className={containerCommonStyles}>
          <div
            className={
              "flex flex-row justify-between max-lg:flex-col [.isMobileView_&]:flex-col"
            }
          >
            <div
              className={classNames(
                "mb-[34px] flex w-full flex-col items-start md:mb-[50px] lg:mb-0 lg:w-[394px] lg:flex-col",
                "[.isMobileView_&]:mb-[34px] [.isMobileView_&]:w-full"
              )}
            >
              <Logo footerLogoLabel={footerLogoLabel} />
            </div>
            <div
              className={classNames(
                "flex flex-wrap gap-y-[30px] pr-0 md:flex-nowrap md:gap-x-[48px] lg:justify-end lg:gap-x-[80px]",
                "[.isMobileView_&]:flex-wrap [.isMobileView_&]:justify-start [.isMobileView_&]:gap-x-0 [.isMobileView_&]:gap-y-[30px] [.isMobileView_&]:gap-y-[30px]"
              )}
            >
              {[
                {
                  label: footerPrimaryListColumnLabel,
                  group: footerPrimaryList,
                },
                {
                  label: footerSecondaryListColumnLabel,
                  group: footerSecondaryList,
                },
                {
                  label: footerExtraListColumnLabel,
                  group: footerExtraList,
                },
                {
                  label: footerCommunityListColumnLabel,
                  group: footerCommunityList,
                },
              ].map(({ label, group }, index) => {
                if (!group.length || !label) return null;
                return (
                  <div
                    className={classNames(
                      "flex w-[50%] flex-col pr-5 text-black-rock md:w-auto md:pr-0",
                      "[.isMobileView_&]:w-[50%]"
                    )}
                    key={index}
                  >
                    <h3 className={"mb-4 font-bold text-[16px] leading-[130%]"}>
                      {label}
                    </h3>
                    <ul
                      className={classNames(
                        "flex flex-col gap-[20px] md:gap-[11px]",
                        "[.isMobileView_&]:gap-[20px]"
                      )}
                    >
                      {group.map((linkItem) => {
                        if (!linkItem.url) return null;
                        const link = correctQuery(
                          linkItem.url,
                          queryArrayIndex
                        );

                        return (
                          <li key={linkItem.id} className={"inline-flex"}>
                            <a
                              href={link}
                              className={classNames(
                                "ga-footer-link text-[14px] leading-[130%] transition-[color] hover:text-cerise",
                                focusCommonStyles
                              )}
                              onClick={async () =>
                                await handleTracking({
                                  text: linkItem.label,
                                  url: linkItem.url as string,
                                })
                              }
                            >
                              {linkItem.label}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className={"mt-[24px] border-t border-[#AAAAB2] pt-[24px]"}>
        <div className={containerCommonStyles}>
          <div
            className={classNames(
              "flex flex-col items-start justify-between gap-[14px] md:flex-row md:items-end md:gap-[24px]",
              "[.isMobileView_&]:flex-col [.isMobileView_&]:items-start [.isMobileView_&]:gap-[14px]"
            )}
          >
            {footerSocials && footerSocials.length > 0 && (
              <ul className={"flex gap-5"}>
                {footerSocials.map((social) => {
                  return (
                    <li className={"flex"} key={social.id}>
                      <IconButton
                        component={"link"}
                        aria-label={social.label}
                        href={social.url}
                        target={"_blank"}
                        rel={"external noopener"}
                        className={"ga-footer-link-social"}
                        onClick={async () =>
                          await handleSocialTracking({
                            text: social.label,
                            url: social.url,
                          })
                        }
                      >
                        <img
                          src={social.image.url}
                          alt={social.image.alt}
                          title={social.image.title}
                          className="h-[24] w-[24]"
                          loading="lazy"
                        />
                      </IconButton>
                    </li>
                  );
                })}
              </ul>
            )}
            <div>
              <Markdown
                text={footerCopyright.replace("$CURRENT_YEAR", `${getYear()}`)}
                className={"text-[14px] leading-[130%] text-mono-70"}
              />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import { shallow } from "zustand/shallow";

import type { SitewideOptions } from "lib/types/sitewideOption";

import { useStore } from "./storeProvider";
import type { StoreState } from "./index";

type SitewideOptionHelpers = Pick<
  StoreState,
  "sitewideOption" | "setSitewideOption"
>;

export const useSitewideOption = (): SitewideOptionHelpers => {
  const { sitewideOption, setSitewideOption } = useStore(
    (store) => ({
      sitewideOption: store.sitewideOption,
      setSitewideOption: store.setSitewideOption,
    }),
    shallow
  );

  return { sitewideOption, setSitewideOption };
};

export const useSitewideOptionData = (): SitewideOptions => {
  const { sitewideOption } = useStore(
    (store) => ({
      sitewideOption: store.sitewideOption,
    }),
    shallow
  );

  return { ...sitewideOption! };
};

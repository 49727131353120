import classNames from "classnames";
import type { NavigationLink } from "lib/types/datoBlocks";

type AccordionContentProps = {
  list: NavigationLink[];
  onTrackClick: (props: { text: string; url: string }) => Promise<void>;
};

const focusCommonStyles =
  "focus:outline-none focus-visible:outline-none focus-visible:ring-inset focus-visible:ring-2 focus-visible:ring focus-visible:ring-cerise";

const AccordionContent = ({ list, onTrackClick }: AccordionContentProps) => {
  return (
    <ul>
      {list.map((link) => {
        if (!link.url) return null;

        return (
          <li key={link.id} className={"border-l border-l-[#CCCCCC]"}>
            <a
              href={link.url}
              className={classNames(
                "ga-nav-link inline-flex w-full flex-col bg-transparent px-[10px] py-3 font-bold text-[14px] leading-[130%] text-mono-70 transition-colors duration-200",
                "hover:bg-[#AAAAB266] active:bg-[#AAAAB2B2]",
                focusCommonStyles
              )}
              onClick={async () =>
                await onTrackClick({
                  text: link.label,
                  url: link.url as string,
                })
              }
            >
              <span className={"inline-flex"}>{link.label}</span>
              {link.description && (
                <span
                  className={"mt-[10px] inline-flex font-normal text-mono-40"}
                >
                  {link.description}
                </span>
              )}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default AccordionContent;

import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import React from "react";
import classNames from "classnames";

type PrimaryTriggerProps = {
  text: string;
};
const PrimaryTrigger = ({ text }: PrimaryTriggerProps) => {
  return (
    <NavigationMenu.Trigger
      className={classNames(
        "text-black-rock relative p-4 font-bold",
        "active:text-cerise [&_.nav-underline]:active:bg-cerise",
        "transition-[background-color,color] duration-200 ease-in-out",
        "focus:outline-none focus-visible:ring focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-cerise"
      )}
    >
      {text}
    </NavigationMenu.Trigger>
  );
};

export default PrimaryTrigger;

import React from "react";
import classNames from "classnames";

import IconButton from "components/iconButton/iconButton";

import styles from "./burgerButton.module.css";

type BurgerProps = {
  opened: boolean;
  ariaControls: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const BurgerButton = ({ opened, ariaControls, onClick }: BurgerProps) => {
  return (
    <IconButton
      component={"button"}
      aria-haspopup={true}
      aria-expanded={opened}
      aria-controls={ariaControls}
      onClick={onClick}
      className={classNames(
        "text-black-rock relative flex h-9 w-9 flex-col items-center justify-between px-[7px] py-2 py-[6px] focus:outline-none"
      )}
    >
      <span className="sr-only">{`${
        opened ? "Close" : "Open"
      } navigation menu`}</span>

      <div className={"relative h-full w-full"}>
        <span className={styles.topSlice} />
        <span className={styles.middleSlice} />
        <span className={styles.bottomSlice} />
      </div>
    </IconButton>
  );
};

export default BurgerButton;

import React, { useEffect, useRef } from "react";
import { useKey } from "react-use";
import dynamic from "next/dynamic";
import classNames from "classnames";

import { CrossNewIcon } from "components/icons/icons";
import IconButton from "components/iconButton/iconButton";
import Footer from "components/footer/footer";
import Markdown from "components/markdown/markdown";

import { useSitewideOptionData } from "lib/store/sitewideOption";
import { useReserveScrollbarWidth } from "lib/hooks/useReserveScrollbarWidth";

import NavLogo from "../navLogo/navLogo";
import AccordionNav from "./accordionNav/accordionNav";
import BottomMenu from "./bottomMenu/bottomMenu";

type SidebarProps = {
  opened: boolean;
  onClose: () => void;
};

const UserTop = dynamic(() => import("./userTop/userTop"), {
  ssr: false,
});

const Sidebar = ({ opened, onClose }: SidebarProps) => {
  const scrollableRef = useRef<HTMLLIElement | null>(null);
  const {
    sidebarHeading,
    logInUrl,
    logInLabel,
    subscribeCtaLabel,
    subscribeCtaUrl,
    sidebarPrimaryLinks,
    userProfileLabel,
    userProfileUrl,
    searchUrl,
    searchLabel,
    logoutLabel,
  } = useSitewideOptionData();

  useEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow;

    // Prevent background from scrolling when sidebar is open
    if (opened) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = originalStyle;
    }
    // Re-enable scrolling when component unmounts
    return function cleanup() {
      document.body.style.overflow = originalStyle;
    };
  }, [opened]);

  useReserveScrollbarWidth({ modalOpened: opened });

  const handleEscape = () => {
    onClose();

    scrollableRef.current!.scrollTop = 0;
  };

  const predicate = (event) => event.key === "Escape" && opened;
  useKey(predicate, handleEscape, { event: "keyup" });

  return (
    <>
      <div
        className={classNames(
          "absolute left-0 top-0 z-20 h-screen w-screen max-w-full overflow-x-hidden bg-black",
          "transition-opacity duration-200 motion-reduce:transition-none",
          {
            "visible opacity-70": opened,
            "invisible opacity-0": !opened,
          }
        )}
        onClick={() => handleEscape()}
        data-testid={"overlay"}
        aria-hidden="true"
      />
      <aside
        ref={scrollableRef}
        aria-label="Sidebar navigation"
        aria-hidden={opened ? undefined : "true"}
        className={classNames(
          "js-fixed-exclude fixed -left-[360px] top-0 z-50 h-screen w-[360px] max-w-[100vw] overflow-y-auto max-xs:-left-[100vw] max-xs:max-w-[100vw]",
          "bg-[#FFFFFF]",
          "transform transition-transform duration-200 ease-out motion-reduce:transition-none",
          opened ? "translate-x-100" : "translate-x-0"
        )}
      >
        <div className={"flex h-screen flex-col"}>
          <div className={"w-full border-b border-b-[#515977] px-5 py-[30px]"}>
            <div className={"flex justify-between"}>
              <div className={"w-[120px]"}>
                <NavLogo />
              </div>
              <div className={"mt-[-4px]"}>
                <IconButton
                  component={"button"}
                  aria-label={"Close sidebar"}
                  onClick={() => handleEscape()}
                >
                  <CrossNewIcon width={24} />
                </IconButton>
              </div>
            </div>
            <div className={"max-w-[90%]"}>
              <Markdown
                options={{ forceBlock: false }}
                className={
                  "mt-0 mt-[30px] font-recklessBold text-[28px] leading-[130%] text-black-rock [&_strong]:text-cerise"
                }
                text={sidebarHeading}
              />
            </div>
            <div className={"flex"}>
              <UserTop
                logInUrl={logInUrl}
                logInLabel={logInLabel}
                subscribeCtaLabel={subscribeCtaLabel}
                subscribeCtaUrl={subscribeCtaUrl}
              />
            </div>
          </div>
          <div
            className={
              "flex w-full flex-1 border-b border-b-[#515977] px-5 py-[10px]"
            }
          >
            <AccordionNav sidebarPrimaryLinks={sidebarPrimaryLinks} />
          </div>
          <div className={"px-5 py-[10px]"}>
            <BottomMenu
              userProfileUrl={userProfileUrl}
              userProfileLabel={userProfileLabel}
              searchLabel={searchLabel}
              searchUrl={searchUrl}
              logoutLabel={logoutLabel}
            />
          </div>
          <div className={"relative"}>
            <Footer mobileView={true} queryArrayIndex={1} />
          </div>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;

import { useEffect, useRef, useState } from "react";
import { Mixpanel } from "lib/mixpanel/mixpanel";

import { useUserData } from "lib/store/user";
import { MIXPANEL_TOKEN } from "lib/constants";

/**
 * Options for `track` function for `useMixpanelTracking` hook
 */
export type MixpanelTrackOptions = {
  /**
   * Run tracking only for authorised users
   * @default: true
   */
  withAuth?: boolean;
};

export type MixpanelProperties = { [key: string]: any };

export const useMixpanelTracking = () => {
  const mixpanel = useRef<Mixpanel | null>(null);
  const [userId, setUserId] = useState<string | null | undefined>(undefined);
  const { pianoId } = useUserData();

  useEffect(() => {
    mixpanel.current = new Mixpanel(MIXPANEL_TOKEN);

    const asyncFn = async () => {
      const id = await pianoId;
      setUserId(id);
    };

    asyncFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const runTracking = (
    mixpanel: Mixpanel,
    eventName: string,
    properties: MixpanelProperties
  ) => {
    mixpanel.track(eventName, properties);
  };

  const track = async (
    eventName: string,
    properties: MixpanelProperties,
    options: MixpanelTrackOptions = {}
  ) => {
    const trackingOptions: Required<MixpanelTrackOptions> = {
      withAuth: options.withAuth !== undefined ? options.withAuth : true,
    };

    if (mixpanel.current) {
      if (trackingOptions.withAuth) {
        if (userId) {
          runTracking(mixpanel.current, eventName, {
            distinct_id: userId,
            ...properties,
          });
        }
      } else {
        runTracking(mixpanel.current, eventName, {
          distinct_id: userId,
          ...properties,
        });
      }
    }
  };

  return { userId, mixpanel, track };
};

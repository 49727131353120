import classNames from "classnames";

import { SiftedLogoFtCurrentColor } from "../assets/siftedLogoFtCurrentColor";

const NavLogo = () => (
  <a
    href="/"
    aria-label={"Go to Homepage"}
    className={classNames(
      "ga-nav-logo flex w-100",
      "focus:outline-none focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-cerise focus-visible:ring-offset-4 focus-visible:ring-offset-[#F6F6F6]"
    )}
  >
    <span className={classNames("text-black-rock")}>
      <SiftedLogoFtCurrentColor />
    </span>
  </a>
);

export default NavLogo;

import * as Accordion from "@radix-ui/react-accordion";
import classNames from "classnames";
import { useRouter } from "next/router";

import type { SitewideOptionsNavbar } from "lib/types/sitewideOption";
import { useMixpanelTracking } from "lib/hooks/useMixpanelTracking";

import AccordionTrigger from "./accordionTrigger/accordionTrigger";
import AccordionContent from "./accordionContent/accordionContent";
import SingleLink from "../singleLink/singleLink";

import styles from "./accordionNav.module.css";

type AccordionNavProps = Pick<SitewideOptionsNavbar, "sidebarPrimaryLinks">;

const AccordionNav = ({ sidebarPrimaryLinks }: AccordionNavProps) => {
  const { track } = useMixpanelTracking();
  const router = useRouter();

  const handleTracking = async ({
    text,
    url,
  }: {
    text: string;
    url: string;
  }) => {
    await track("Navigation link click", {
      "Click text": text,
      "Click link": url,
      "Page path": router.asPath,
      Component: "Sidebar",
    });
  };

  return (
    <>
      <Accordion.Root type={"single"} collapsible={true} className={"w-full"}>
        <ul>
          {sidebarPrimaryLinks.map((primaryLink, index) => {
            if (!primaryLink.list || primaryLink.list.length === 0) {
              if (primaryLink.url) {
                return (
                  <li key={`${primaryLink.id}${index}`}>
                    <SingleLink
                      href={primaryLink.url}
                      className={"ga-nav-link"}
                      onClick={async () =>
                        await handleTracking({
                          text: primaryLink.label,
                          url: primaryLink.url as string,
                        })
                      }
                    >
                      {primaryLink.label}
                    </SingleLink>
                  </li>
                );
              } else {
                return null;
              }
            }

            return (
              <li key={index}>
                <Accordion.Item value={primaryLink.label} className={"w-full"}>
                  <AccordionTrigger>{primaryLink.label}</AccordionTrigger>
                  <Accordion.Content
                    className={classNames(
                      "data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp overflow-hidden",
                      styles.content
                    )}
                  >
                    <AccordionContent
                      list={primaryLink.list}
                      onTrackClick={handleTracking}
                    />
                  </Accordion.Content>
                </Accordion.Item>
              </li>
            );
          })}
        </ul>
      </Accordion.Root>
    </>
  );
};

export default AccordionNav;

import React from "react";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";

import type { SitewideOptionsNavbar } from "lib/types/sitewideOption";
import { useMixpanelTracking } from "lib/hooks/useMixpanelTracking";

import { SearchNewIcon } from "components/icons/icons";

import SingleLink from "../singleLink/singleLink";

const BottomUser = dynamic(() => import("./bottomUser/bottomUser"), {
  ssr: false,
});

type BottomMenuProps = Pick<
  SitewideOptionsNavbar,
  | "searchUrl"
  | "searchLabel"
  | "userProfileUrl"
  | "userProfileLabel"
  | "logoutLabel"
>;

const BottomMenu = ({
  searchLabel,
  searchUrl,
  userProfileLabel,
  userProfileUrl,
  logoutLabel,
}: BottomMenuProps) => {
  const { track } = useMixpanelTracking();
  const router = useRouter();

  const handleTracking = async ({
    text,
    url,
  }: {
    text: string;
    url: string;
  }) => {
    await track("Navigation link click", {
      "Click text": text,
      "Click link": url,
      "Page path": router.asPath,
      Component: "Sidebar",
    });
  };

  return (
    <ul>
      <li>
        <SingleLink
          className={"ga-nav-link-search w-full"}
          href={searchUrl}
          onClick={async () =>
            await handleTracking({ text: searchLabel, url: searchUrl })
          }
        >
          <span className={"inline-flex w-full justify-between"}>
            <span>{searchLabel}</span>
            <span>
              <SearchNewIcon width={24} />
            </span>
          </span>
        </SingleLink>
      </li>
      <BottomUser
        logoutLabel={logoutLabel}
        userProfileLabel={userProfileLabel}
        userProfileUrl={userProfileUrl}
      />
    </ul>
  );
};

export default BottomMenu;

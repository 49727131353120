import { useEffect } from "react";
import { useScrollbarWidth } from "react-use";

type UseReserveScrollbarWidthProps = {
  modalOpened: boolean;
};

export const useReserveScrollbarWidth = ({
  modalOpened,
}: UseReserveScrollbarWidthProps) => {
  const sbw = useScrollbarWidth();

  useEffect(() => {
    const allFixedElements = document.querySelectorAll(
      ".fixed:not(.js-fixed-exclude)"
    );

    if (modalOpened && sbw) {
      document.body.style.paddingRight = `${sbw}px`;

      allFixedElements.forEach((fixedEl: HTMLElement) => {
        const computedPaddingRight = parseInt(
          window.getComputedStyle(fixedEl).getPropertyValue("padding-right") ||
            "0",
          10
        );
        fixedEl.style.paddingRight = `${computedPaddingRight + sbw}px`;
      });
    } else {
      document.body.style.paddingRight = `0px`;

      allFixedElements.forEach((fixedEl: HTMLElement) => {
        fixedEl.style.paddingRight = `0px`;
      });
    }
  }, [modalOpened, sbw]);
};

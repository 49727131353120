import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import React from "react";
import classNames from "classnames";

import { useSitewideOptionData } from "lib/store/sitewideOption";
import { useMixpanelTracking } from "lib/hooks/useMixpanelTracking";

import ListItem from "./listItem/listItem";
import PrimaryTrigger from "./primaryTrigger/primaryTrigger";
import { useRouter } from "next/router";

const PrimaryMenu = () => {
  const { navbarPrimaryLinks } = useSitewideOptionData();
  const { track } = useMixpanelTracking();
  const router = useRouter();

  const handleTracking = async ({
    text,
    url,
  }: {
    text: string;
    url: string;
  }) => {
    await track("Navigation link click", {
      "Click text": text,
      "Click link": url,
      "Page path": router.asPath,
      Component: "Navigation",
    });
  };

  return (
    <NavigationMenu.List className="flex">
      {navbarPrimaryLinks.map((section) => {
        if (section.list?.length === 0) {
          if (section.url) {
            return (
              <NavigationMenu.Link
                key={section.id}
                href={section.url}
                onClick={async () =>
                  await handleTracking({
                    text: section.label,
                    url: section.url as string,
                  })
                }
                className={classNames(
                  "ga-nav-link text-black-rock relative p-4 font-bold",
                  "active:text-cerise [&_.nav-underline]:active:bg-cerise",
                  "transition-[background-color,color] duration-200 ease-in-out",
                  "focus:outline-none focus-visible:ring focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-cerise"
                )}
              >
                {section.label}
              </NavigationMenu.Link>
            );
          }

          return null;
        }

        return (
          <NavigationMenu.Item key={section.id}>
            <PrimaryTrigger text={section.label} />
            <NavigationMenu.Content className="w-full py-5">
              <ul className="m-auto grid max-w-[1000px] grid-cols-4 gap-x-5 gap-y-[18px]">
                {section.list?.map((listItem, index) => {
                  if (!listItem.url) return null;

                  return (
                    <li key={index}>
                      <ListItem
                        title={listItem.label}
                        href={listItem.url}
                        onTrackEvent={handleTracking}
                      >
                        {listItem.description}
                      </ListItem>
                    </li>
                  );
                })}
              </ul>
            </NavigationMenu.Content>
          </NavigationMenu.Item>
        );
      })}
      <NavigationMenu.Indicator className="data-[state=visible]:animate-fadeIn data-[state=hidden]:animate-fadeOut bottom-[-5px] z-[1] flex items-end justify-center overflow-hidden transition-[width,transform_250ms_ease]">
        <div className="nav-underline bg-black-rock absolute relative left-0 right-0 h-[3px] h-[3px] w-full" />
      </NavigationMenu.Indicator>
    </NavigationMenu.List>
  );
};

export default PrimaryMenu;
